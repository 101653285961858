import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { SearchForm } from 'src/components/formComponents/search/searchForm';
import { SearchResults } from 'src/components/formComponents/search/searchResults';
import { SectionContainer, SectionContainerTitle } from 'src/components/sectionContainer';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import { useSearchManager, FilterCollection, LinkState } from 'src/utils/search-manager';
import { curatedTitle } from 'src/utils/curated-title';

interface Props {
	languages: string[];
}

export const Search = ({ languages }: Props) => {
	const useSearch = useSearchManager();
	let [showFilters, setShowFilters] = useState(false);
	let state: LinkState | null = null;

	useEffect(() => {
		if (typeof window !== 'undefined') {
			state = window?.history?.state;
		}
		if (state) {
			if (state.filters) {
				useSearch.setFilterCollection(state.filters);
				setShowFilters(true);
			}
			if (state.query) {
				useSearch.queryFor(state.query);
			}
		}
	}, []);

	return (
		<>
			<SectionContainer withBorderTop>
				<SectionContainerTitle
					text="How can we help?"
					titleStyle={{ color: '#323E6B' }}
					style={{ margin: '20px 0 40px 0' }}
				/>
				<SearchForm useSearch={useSearch} showFilters={showFilters} languages={languages} />
			</SectionContainer>
			<SectionContainer isGrey withBorderTop>
				<div>
					<SearchResults useSearch={useSearch} />
				</div>
			</SectionContainer>
		</>
	);
};

export default class SearchPage extends React.Component<any> {
	render() {
		return (
			<DefaultLayoutTemplate>
				<Helmet>
					<title>{curatedTitle('Search')}</title>
				</Helmet>
				<Search languages={this.props.pageContext.languages} />
			</DefaultLayoutTemplate>
		);
	}
}
