import styles from './styles.module.css';
import classNames from 'classnames';
import React from 'react';
import Masonry from 'react-masonry-css';
import { FilterInputs } from 'src/components/formComponents/search/searchForm';
import { ReadMore } from 'src/components/readMore';
import { SectionContainerTitle } from 'src/components/sectionContainer';
import { SectionContainer, SectionContainerFlex } from 'src/components/sectionContainer';
import * as Icons from 'src/icons';
import { ResultStatus, SearchResult, UseSearchManagerResult } from 'src/utils/search-manager';

import {
	CustomSortBySelectlist,
	notSelected,
} from 'src/components/formComponents/customSelectlist';
import { formatDate } from 'src/utils/common';

export type SortByItemType = 'dateDesc' | 'dateAsc' | 'titleAsc' | 'titleDesc';

interface SortByItem {
	value: SortByItemType;
	label: string;
}

export const sortByItems: SortByItem[] = [
	{ value: 'dateDesc', label: 'DATE' },
	{ value: 'dateAsc', label: 'DATE - oldest' },
	{ value: 'titleAsc', label: 'TITLE A-Z' },
	{ value: 'titleDesc', label: 'TITLE Z-A' },
];

interface Props {
	useSearch: UseSearchManagerResult;
}

export const SearchResults = (props: Props) => {
	const { useSearch } = props;

	const getMessageJsx = (message: string) => {
		return <h5 className={styles.message}>{message}</h5>;
	};

	const itHasItems = (result: SearchResult) => {
		return result && result.data && result.data.items && result.data.items.length > 0;
	};

	const getContentJsx = () => {
		switch (useSearch.result.status) {
			case ResultStatus.nothingSearched:
				return getMessageJsx('Start searching by selecting a filter or typing a keyword');
			case ResultStatus.searching:
				return getMessageJsx('Searching...');
			case ResultStatus.error:
				return getMessageJsx('An error has ocurred, please try again later.');
			case ResultStatus.success:
				if (!itHasItems(useSearch.result)) {
					return getMessageJsx('There are no results with the current search criteria');
				}

				const breakpointColumnsObj = {
					default: 3,
					1200: 2,
					960: 2,
					600: 1,
				};

				return (
					<Masonry
						breakpointCols={breakpointColumnsObj}
						className={styles.masonryGrid}
						columnClassName={styles.masonryGridColumn}
					>
						{useSearch.result.data?.items?.map((item) => {
							return (
								<ReadMore
									key={item.contentful_id}
									className={styles.readMoreItem}
									asCard
									image={{
										src: item.thumbnailLink?.imageSrc,
										style: {
											maxHeight: '300px',
										},
										imageContainerStyle: {
											display: 'flex',
											justifyContent: 'center',
										},
									}}
									icon={{
										Icon:
											Icons[
												(item.thumbnailLink?.thumbnailIcon || item.icon) as keyof typeof Icons
											] || undefined,
										style: {
											maxHeight: '300px',
											display: 'block',
										},
										iconContainerStyle: {
											display: 'flex',
											justifyContent: 'center',
										},
									}}
									publishedDate={formatDate(item.publicationDate)}
									title={{
										titleText: item.thumbnailLink?.title || item.title,
									}}
									linkSection={{
										// open in a new window/tab to avoid losing search results
										externalLink: item.link?.url || '/',
										readMoreText: item.link?.text || 'Read More',
										typeText: item.resourceType,
										withBorderTop: true,
									}}
									asVideoUrl={item.videoUrl}
								>
									{item.thumbnailLink?.description || item.description}
								</ReadMore>
							);
						})}
					</Masonry>
				);
		}
	};

	const getSelectedOption = (filter: FilterInputs) => {
		const selectedOption = filter.items.find((item) => useSearch.hasFilter(filter.groupKey, item));
		if (!selectedOption) {
			return notSelected;
		}
		return selectedOption;
	};

	const sortByFilter: FilterInputs = {
		groupKey: 'sort',
		groupLabel: 'SORT BY',
		type: 'selectlist',
		items: sortByItems.map((item) => item.value),
	};

	const getOnLoadMore = () => {
		if (
			useSearch.result.data?.nextItemNumber !== undefined &&
			useSearch.result.data?.nextItemNumber > 0
		) {
			return useSearch.loadMore;
		}
		return undefined;
	};

	return (
		<SectionContainer className={styles.searchResultsContainer} onLoadMore={getOnLoadMore()}>
			<SectionContainerFlex className={styles.searchResultsTitleContainer}>
				<SectionContainerTitle text="Search Results" className={styles.searchResultsTitle} />
				{itHasItems(useSearch.result) && (
					<CustomSortBySelectlist
						name={sortByFilter.groupKey}
						placeHolder={sortByFilter.groupLabel}
						values={sortByItems}
						selectedOptionValue={getSelectedOption(sortByFilter)}
						onChange={(event) => {
							const value = event.target.value === notSelected ? undefined : event.target.value;
							useSearch.onFilterChange('sort', value, true, true);
						}}
					/>
				)}
			</SectionContainerFlex>
			{getContentJsx()}
		</SectionContainer>
	);
};
