import styles from './styles.module.css';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { SectionContainerGrid } from 'src/components/sectionContainer';
import { ArrowCircle } from 'src/icons/arrowCircle';
import { CaretIcon } from 'src/icons/caret';
import { SearchIcon } from 'src/icons/search';
import { FilterCollection } from 'src/utils/search-manager';
import {
	CustomControlledSelectlist,
	notSelected,
} from 'src/components/formComponents/customSelectlist';
import { UseSearchManagerResult } from 'src/utils/search-manager';
import { FilterContentTypeItems } from 'src/utils/common';

export interface FilterInputs {
	groupKey: keyof FilterCollection;
	groupLabel: string;
	type: 'pills' | 'selectlist';
	items: string[];
}

interface Props {
	useSearch: UseSearchManagerResult;
	showFilters?: boolean;
	languages?: string[];
}

export const SearchForm = (props: Props) => {
	const [isShowFilters, setIsShowFilters] = useState(false);
	const useSearch = props.useSearch;

	useEffect(() => {
		const propsShowFilters = props.showFilters || false;
		if (isShowFilters !== propsShowFilters) {
			setIsShowFilters(propsShowFilters);
		}
	}, [props.showFilters]);

	const filtersInputs: FilterInputs[] = [
		{
			groupKey: 'supplyChain',
			groupLabel: 'Supply Chain',
			type: 'pills',
			items: ['Domestic', 'Export by Sea', 'Export by Air', 'In Market'],
		},
		{
			groupKey: 'contentTypes',
			groupLabel: 'Content Type',
			type: 'pills',
			items: Object.values(FilterContentTypeItems),
		},
		{
			groupKey: 'species',
			groupLabel: 'Species',
			type: 'pills',
			items: ['Dairy Cattle', 'Beef Cattle', 'Sheep', 'Goats', 'Other'],
		},
		{
			groupKey: 'languages',
			groupLabel: 'Language',
			type: 'selectlist',
			items: props.languages || ['English', 'Other'],
		},
	];

	const submit = (event: React.SyntheticEvent) => {
		useSearch.triggerSearch();
		event.preventDefault();
	};

	const onToggleFilters = () => {
		setIsShowFilters((isShowFilters) => {
			if (isShowFilters) {
				useSearch.clearAllFilters();
			}
			return !isShowFilters;
		});
	};

	const changeFilter = (
		filterGroup: keyof FilterCollection,
		filterElement: string | undefined,
		status: boolean,
		isExclusive: boolean = false
	) => {
		useSearch.onFilterChange(filterGroup, filterElement, status, isExclusive);
	};

	const getSelectedOption = (filter: FilterInputs) => {
		const selectedOption = filter.items.find((item) => useSearch.hasFilter(filter.groupKey, item));
		if (!selectedOption) {
			return notSelected;
		}
		return selectedOption;
	};

	const getFilterInput = (filter: FilterInputs) => {
		if (filter.type === 'pills') {
			return filter.items.map((item) => {
				const key = `Filters - ${filter.groupKey} - ${item}`;
				return (
					<div className={styles.pillContainer} key={key}>
						<label>
							<input
								type="checkbox"
								name={key}
								checked={useSearch.hasFilter(filter.groupKey, item)}
								onChange={(event) => changeFilter(filter.groupKey, item, event.target.checked)}
							/>
							<span>{item}</span>
						</label>
					</div>
				);
			});
		} else {
			const options = filter.items.map((item) => {
				return { value: item, label: item };
			});
			return (
				<CustomControlledSelectlist
					name={`Filters - ${filter.groupKey}`}
					placeHolder="Please select..."
					values={options}
					outerContainerStyle={{ maxWidth: '350px' }}
					selectedOptionValue={getSelectedOption(filter)}
					onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
						const value = event.target.value === notSelected ? undefined : event.target.value;
						changeFilter(filter.groupKey, value, true, true);
					}}
				/>
			);
		}
	};

	const filtersJsx = (
		<div className={styles.filterContainer}>
			<div className={styles.filterTitle}>Filters</div>
			<SectionContainerGrid columns="2" style={{ gridColumnGap: '40px' }}>
				{filtersInputs.map((filter) => {
					return (
						<div key={filter.groupKey}>
							<div className={styles.filterGroupTitle}>{filter.groupLabel}</div>
							<div>{getFilterInput(filter)}</div>
						</div>
					);
				})}
			</SectionContainerGrid>
		</div>
	);

	return (
		<form onSubmit={(event) => submit(event)}>
			<div className={styles.searchTopContainer}>
				<div className={styles.inputContainer}>
					<div className={styles.inputInnerContainer}>
						<span className={styles.iconLeft}>
							<SearchIcon color="#52D07A" />
						</span>
						<input
							name="query"
							value={useSearch.query}
							type="text"
							className={styles.searchInput}
							placeholder="Search by keyword"
							alt="Search by keyword"
							onChange={(event) => useSearch.onQueryChange(event.target.value)}
						/>
						<button type="submit" className={styles.iconRight} onClick={(event) => submit(event)}>
							<ArrowCircle color="#52D07A" />
						</button>
					</div>
				</div>
				<button type="button" onClick={onToggleFilters} className={styles.toggleFilters}>
					{isShowFilters ? 'HIDE ' : 'SHOW '} FILTERS
					<CaretIcon direction={isShowFilters ? 'up' : 'down'} />
				</button>
			</div>
			{isShowFilters && filtersJsx}
		</form>
	);
};
